export const references = [
  {
    content: 'Ahmed I, Thorpy M. Clinical features, diagnosis and treatment of narcolepsy. ',
    source: 'Clin Chest Med. ',
    info: '2010;31(2):371-381.',
  },
  {
    content:
      'Carter LP, Acebo C, Kim A. Patients’ journeys to a narcolepsy diagnosis: a physician survey and retrospective chart review. ',
    source: 'Postgrad Med. ',
    info: '2014;126(3):216-224.',
  },
  {
    content:
      'Luca G, Haba-Rubio J, Dauvilliers Y, et al; European Narcolepsy Network. Clinical, polysomnographic and genome-wide association analyses of narcolepsy with cataplexy: a European Narcolepsy Network study. ',
    source: 'J Sleep Res. ',
    info: '2013;22(5):482-495.',
  },
  {
    content: 'Thorpy MJ, Krieger AC. Delayed diagnosis of narcolepsy: characterization and impact. ',
    source: 'Sleep Med. ',
    info: '2014;15(5):502-507.',
  },
  {
    content: 'American Academy of Sleep Medicine. Central disorders of hypersomnolence. In: ',
    source: 'The International Classification of Sleep Disorders – Third Edition (ICSD-3) Online Version.',
    info: 'Darien, IL: American Academy of Sleep Medicine; 2014.',
  },

  {
    content:
      'Thorpy MJ, Dauvilliers Y. Clinical and practical considerations in the pharmacologic management of narcolepsy. ',
    source: 'Sleep Med. ',
    info: '2015;16(1):9-18.',
  },
  {
    content: 'Cheung J, Ruoff CM, Mignot E. Central nervous system hypersomnias. In: Miglis MG, ed.',
    source: ' Sleep and Neurologic Disease.',
    info: 'Cambridge, MA: Elsevier Academic Press; 2017:141-166.',
  },
  {
    content: 'Roth T, Dauvilliers Y, Mignot E, et al. Disrupted nighttime sleep in narcolepsy.',
    source: 'J Clin Sleep Med.',
    info: '2013;9(9):955-965.',
  },
  {
    content:
      'Moturi S, Ivanenko A. Complex diagnostic and treatment issues in psychotic symptoms associated with narcolepsy. ',
    source: 'Psychiatry (Edgmont).',
    info: '2009;6(6):38‐44.',
  },
  {
    content: 'Pagel JF. Excessive daytime sleepiness.',
    source: ' Am Fam Physician.',
    info: '2009;79(5):391-396.',
  },
  {
    content: 'Miglis MG, Kushida CA. Daytime sleepiness. ',
    source: 'Sleep Medicine Clinics. ',
    info: '2014;9(4):491-498. Available at: https://www.sleep.theclinics.com/article/S1556-407X(14)00083-6/pdf. Accessed April 30, 2021.',
  },
]