import React, { Component } from 'react'

import Layout from '@components/layout/Layout'
import LeavingSiteModal from '@components/leaving-site-modal/leaving-site-modal.component'
import LearnMoreComponent from '@components/learn-more/learn-more.component'
import ReferenceComponent from '@components/references/reference.component'
import BrightcoveVideoComponent from '@components/brightcove-video/brightcove-video.component'
import { videos } from '@components/video-library/videos'
import ReadNext from '@components/read-next-button/read-next-button'
import { references } from '@components/references/differential-diagnosis-references'

import mainSymptoms from '@assets/images/infographics/infographic-main-symptoms.svg'
import otherConditions from '@assets/images/infographics/infographic-other-conditions.svg'
import imgChallengesThumbnail from '@assets/images/thumbnails/narcolepsy-patient-perspectives-diagnosing-challenges-video.jpeg'

import { trackGa } from '@components/GoogleTracking'

import './differential-diagnosis.scss'

class DifferentialDiagnosis extends Component {

  constructor(props){
    super(props)

    this.learnMoreContent = {
      className: 'learn-more-wrapper-gray',
      title: 'Could your patient have idiopathic hypersomnia instead of narcolepsy?',
      externalLink: true, 
      link: 'https://sleepcountshcp.com/difference-between-idiopathic-hypersomnia-and-narcolepsy',
      linkLabel: 'Review the differences',
      tracking: 'diagnostic challenges,	click, diagnostic-challenge-IH-CTA',
    }

    this.difficultJourneyToDiagnosis = videos.difficultJourneyToDiagnosis

    this.readNext = {
      readNextContent: (<span>Cardiovascular <br/> comorbidities in narcolepsy</span>),
      goTo: '/comorbidities-risk/cardiovascular-comorbidities/',
      tracking: 'diagnostic challenges,	click,	comorbidities - diagnostic challenges',
    }

    this.state = {
      showLeavingSiteModal: false,
      href: '',
    }

    this.handleShowModalClicked = this.handleShowModalClicked.bind(this)
    this.handleHideModal = this.handleHideModal.bind(this)
    this.openLink = this.openLink.bind(this)
  }


  handleHideModal() {
    this.setState({
      showLeavingSiteModal: false,
    })
  }

  handleShowModalClicked() {
    this.setState({
      showLeavingSiteModal: true,
    })
  }

  openLink(link) {
    trackGa('diagnostic challenges, click, diagnostic-challenge-IH-CTA')
    this.setState(() => {
      return { href: link }
    }, this.handleShowModalClicked())
  }

  render(){
    return (
      <Layout addedClass='page-differential-diagnosis' location={this.props.location}>
        <div
          id="background-wrapper"
          className="page-background-wrapper"
          role="img"
          aria-label="Misdiagnosis - Differential Diagnosis"
          alt="Clouds young man walking rocky terrain carrying boulders on back"
        >
          <div className="primary-container">
            <h1 className="hero-title">Comorbidities May Mask the Symptoms of Narcolepsy</h1>
            <div className="hero-comment">
              Studies indicate onset of symptoms occurs most commonly between 10 and 25 years of age; however, diagnosis is often missed and/or delayed for more than a decade due to the overlap with symptoms of other medical conditions.<sup>1-5</sup>
            </div>
          </div>
        </div>
        <div className="lower-wrapper">
          <div className="primary-container">
            <div className="flex-header-learn-more">
              <h2 className="lower-header">Narcolepsy Can Be Difficult to Diagnose</h2>
              <LearnMoreComponent learnMoreContent={this.learnMoreContent} externalLinkHandler={this.openLink} />
            </div>
            <div className="inner-wrapper">
              <div className="inner-container">
                <h2 className="inner-header symptoms-title-header">
                  Symptoms of narcolepsy can overlap with or mimic the symptoms of other conditions<sup>1,2,4</sup>
                </h2>
                <div className="image-wrapper">
                  <div className="infographic-container main-symptoms">
                    <h2 className="inner-header">5 main symptoms of narcolepsy<sup>5,6</sup></h2>
                    <img src={mainSymptoms} className="misdiagnosis-image" alt="5 CHESS descriptions " />
                  </div>
                  <div className="infographic-container other-symptoms">
                    <h2 className="inner-header">Other symptoms and conditions</h2>
                    <img
                      src={otherConditions}
                      className="misdiagnosis-image fix-margin"
                      alt="10 other conditions and symptoms"
                    />
                  </div>
                </div>
                <h2 className="inner-header">Is It Narcolepsy?</h2>
                <div className="inner-p">
                  In a physician survey and retrospective chart review study of 252 patients with narcolepsy, 60% of
                  patients had initially received a misdiagnosis of another disorder. The most common misdiagnoses
                  included depression (~31%), insomnia (~18%), and obstructive sleep apnea (OSA) (~13%).<sup>2</sup>
                </div>
                <h2 className="inner-header">A patient’s perspective</h2>
                <div className="inner-p">
                  Watching patients describe the difficulty of getting diagnosed can help you discuss, recognize symptoms,
                  and understand your patient’s life-long journey.
                </div>
                <BrightcoveVideoComponent
                  player={this.difficultJourneyToDiagnosis.player}
                  account={this.difficultJourneyToDiagnosis.account}
                  videoId={this.difficultJourneyToDiagnosis.videoId}
                  tracking={this.difficultJourneyToDiagnosis.tracking}
                  trackPercentComplete={this.difficultJourneyToDiagnosis.trackPercentComplete}
                  src={imgChallengesThumbnail}
                />
              </div>
            </div>
            <div className="margin-top-30" />
            <ReadNext readNext={this.readNext} />
          </div>
        </div>
        <ReferenceComponent referenceList={references} />
        <LeavingSiteModal
          isShowModal={this.state.showLeavingSiteModal}
          onClose={this.handleHideModal}
          href={this.state.href}
          tracking={'diagnostic challenges, click, IH redirect - diagnostic challenge'}
          cancelTracking={'diagnostic challenges, click, cancel IH redirect - diagnostic challenge'}
          exitTracking={'diagnostic challenges, click, sleepcountshcp exit overlay - management considerations'}
          liveRampTrackingString={'sleepcountshcp-leaving-site-click'}
          location={this.props.location}
        />
      </Layout>
    )
  }
  
}

export default DifferentialDiagnosis
